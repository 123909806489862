.password-strength-meter {
    text-align: left;
  }
  
  .password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 8px;
  }
  
  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  
  .password-strength-meter-label {
    font-size: 14px;
  }
  
  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
  
  .strength-Weak::-webkit-progress-value {
    background-color: #f5365c;
  }
  .strength-Fair::-webkit-progress-value {
    background-color: #fb6340;
  }
  .strength-Good::-webkit-progress-value {
    background-color: #11cdef;
  }
  .strength-Strong::-webkit-progress-value {
    background-color: #2dce89;
  }

  .label-strength-Weak {
    color: #f5365c;
  }
  .label-strength-Fair {
    color: #fb6340;
  }
  .label-strength-Good {
    color: #11cdef;
  }
  .label-strength-Strong {
    color: #2dce89;
  }

  .pg-canvas {    
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    opacity: .5;
  }

  #sidebar-scrollbar .pg-canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    opacity: .3;
  }  
  
  #usercardsheader .pg-canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    opacity: .4;
  }
  
  #admin-navbar .pg-canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    opacity: .4;
  }