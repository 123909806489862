#launcher-frame .blzvQB.launcher-button {
    position: absolute;
    background-color: #744fc4;
}

.blzvQB.launcher-button:hover {
    position: absolute;
    background-color: #744fc4;
}

#launcher-frame {
    left: -55px !important;
    bottom: 12px !important;
}