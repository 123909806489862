@mixin alert-variant($background, $border, $color) {
    color: color-yiq($background);
    border-color: $border;
    @include gradient-bg($background);

    a {
        color: darken($background, 30%);
        font-weight: 600;

        &:hover {
            color: color-yiq($background);
        }
    }

    hr {
        border-top-color: darken($border, 5%);
    }

    .alert-link {
        color: darken($color, 10%);
    }
}
