@mixin modal-variant($background) {
	.modal-title {
		color: color-yiq($background);
	}

	.modal-header,
	.modal-footer {
		border-color: rgba(color-yiq($background), .075);
	}

	.modal-content {
		background-color: $background;
		color: color-yiq($background);

		.heading {
			color: color-yiq($background);
		}
	}

	.close {
    	& > span:not(.sr-only) {
    		color: $white;
    	}
    }
}
