//
// Navbar search
//

.navbar-search {
    .input-group {
        border-radius: $navbar-search-border-radius;
        border: $navbar-search-border-width solid;
        @include transition($transition-bg);
        transition-delay: $transition-base-time;

        .input-group-text {
            background-color: transparent;
            padding-left: 1rem;
            border: 0;
        }
    }

    .form-control {
        width: $navbar-search-width;
        background-color: transparent;
        border: 0;
        @include transition($navbar-search-transition);
    }

    .focused {
        .input-group {
            .form-control {
                width: $navbar-search-focus-width;
            }
        }
    }

    .close {
        display: none;
    }
}


// Search variations

.navbar-search-dark {
    .input-group {
        background-color: $navbar-search-dark-bg;
        border-color: $navbar-search-dark-border-color;
    }

    .input-group-text {
        color: $navbar-search-dark-color;
    }

    .form-control {
        color: $navbar-search-dark-focus-color;

        &::placeholder {
            color: $navbar-search-dark-color;
        }
    }

    .focused {
        .input-group {
            background-color: $navbar-search-dark-focus-bg;
            border-color: $navbar-search-dark-focus-border-color;
        }
    }
}

.navbar-search-light {
    .input-group {
        background-color: $navbar-search-light-bg;
        border-color: $navbar-search-light-border-color;
    }

    .input-group-text {
        color: $navbar-search-light-color;
    }

    .form-control {
        color: $navbar-search-light-focus-color;

        &::placeholder {
            color: $navbar-search-light-color;
        }
    }

    .focused {
        .input-group {
            background-color: $navbar-search-light-focus-bg;
            border-color: $navbar-search-light-focus-border-color;
        }
    }
}

// When navbar search is open on xs
@include media-breakpoint-down(xs) {
    .navbar-search {
        display: none;
        width: 100%;
        transform: translateX(-150%);
        @include transition($transition-cubic-bezier);

        .form-group {
            width: 100%;
        }

        .form-control {
            width: auto;
        }

        .focused {
            .input-group {
                .form-control {
                    width: auto;
                }
            }
        }

        .close {
            display: none;
            opacity: 0;

            span {
                width: auto;
                height: auto;
            }
        }
    }

    .navbar-top {
        .navbar-nav {
            @include transition($transition-base);
        }
    }

    .g-navbar-search-showing {
        .navbar-search {
            .close {
                display: block;
            }
        }

        .navbar-top {
            .navbar-nav {
                display: flex;
                opacity: 1;
                transform: translateX(150%);
            }
        }
    }

    .g-navbar-search-show {
        // Search
        .navbar-search {
            display: block;

            .form-control {
                width: auto;
            }

            .close {
                color: white;
                position: absolute;
                top: 0;
                right: 0;
                width: 46px;
                height: 46px;
                text-align: center;
                line-height: 46px;
                cursor: pointer;
            }
        }

        // Navbar
        .navbar-top {
            .navbar-nav {
                display: none;
            }

            // Collapse
            .navbar-collapse {
                width: 100%;
            }
        }
    }

    .g-navbar-search-shown {
        .navbar-search {
            transform: translateX(0);

            .close {
                display: block;
                opacity: 1;
            }
        }
    }

    .g-navbar-search-hiding {
        // Navbar
        .navbar-top {
            .navbar-nav {
                display: flex;
                opacity: 0;
                transform: translateX(150%);
            }
        }
    }

    .g-navbar-search-hidden {
        .navbar-top {
            .navbar-nav {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
